import styles from './Layout.module.scss'
import classNames from 'classnames'
import React from 'react'
import { LayoutCard } from '../LayoutCard/LayoutCard'
import { IconLeftArrow } from '../../icons/IconLeftArrow'
import { useStepsContext } from '~/src/stepsContext'
import { PageLoader } from '../ui/PageLoader/PageLoader'

type LayoutProps = {
  back?: boolean
  withCard?: boolean
  fullHeight?: boolean
  children?: React.ReactNode
}

export const Layout: React.FC<LayoutProps> = ({
  fullHeight = false,
  back,
  withCard,
  children,
}) => {
  const { isInitRequestLoading, brand, setCurrentStep, setUserPhoneNumber } =
    useStepsContext()

  const handleClickBack = () => {
    setUserPhoneNumber('')
    setCurrentStep('start')
  }

  return (
    <div
      className={classNames(styles.layout, {
        [styles['full-height']]: fullHeight,
      })}
    >
      <header className={styles.layout__header}>
        <div className={styles.inner}>
          <div
            className={styles.logo}
            style={{ backgroundImage: `url(${brand?.logo})` }}
          />
        </div>
      </header>

      <div className={styles.layout__content}>
        <div className={styles.inner}>
          <div className={styles.layout__main}>
            {back && (
              <div className={styles.layout__back} onClick={handleClickBack}>
                <IconLeftArrow />
              </div>
            )}

            {withCard && (
              <div className={styles.layout__card}>
                <LayoutCard />
              </div>
            )}

            {isInitRequestLoading ? <PageLoader /> : children}
          </div>
        </div>
      </div>

      <footer className={styles.layout__footer}>
        <div className={styles.inner}>Сделано на технологии MAXMA.com</div>
      </footer>
    </div>
  )
}
