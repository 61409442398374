import { QRCodeCanvas } from 'qrcode.react'
import styles from './QRCodePage.module.scss'
import { useStepsContext } from '~/src/stepsContext'
import { Layout } from '~/src/components/Layout/Layout'
import { InnerPage } from '~/src/components/InnerPage/InnerPage'

export const QRCodePage: React.FC = () => {
  const { walletsLink } = useStepsContext()

  return (
    <Layout withCard back>
      <InnerPage title='Для загрузки карты наведите камеру телефона на QR-код'>
        {walletsLink && (
          <div className={styles.qr}>
            <div className={styles.qrcode}>
              <QRCodeCanvas
                value={walletsLink}
                className={styles.qrcode_inner}
                size={140}
                level='L'
              />
            </div>
          </div>
        )}

        <div className={styles['qr-timer']}>
          <div className={styles['qr-timer__label']}>
            Или перейдите по ссылке в браузере вашего телефона:
          </div>
          <a
            className='timer__timeout link'
            href={walletsLink ? walletsLink : undefined}
            target='__blank'
          >
            {walletsLink && walletsLink.split('//')[1]}
          </a>
        </div>
      </InnerPage>
    </Layout>
  )
}
