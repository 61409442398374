import React, { useEffect, useRef, useState } from 'react'
import Timer from './Timer'
import { Input } from '../../ui/Input/Input'
import styles from './SendCode.module.scss'
import { useApi } from '~/src/api'
import { SubmitPhoneResponse } from '~/src/types'
import { useStepsContext } from '~/src/stepsContext'
import classNames from 'classnames'

type SendCodeProps = {
  refetchCode: () => void
}

export const SendCode: React.FC<SendCodeProps> = ({ refetchCode }) => {
  const [smsCode, setSmsCode] = useState<string[]>([])

  const inputOne = useRef<HTMLInputElement | null>(null)
  const inputTwo = useRef<HTMLInputElement | null>(null)
  const inputThree = useRef<HTMLInputElement | null>(null)

  const inputRefs = [inputOne, inputTwo, inputThree]

  const { userPhoneNumber, setCommonClientData, setCurrentStep } =
    useStepsContext()

  const handleInputChange = (index: number, value: string) => {
    resetError()
    if (value && !/^\d+$/.test(value)) {
      return
    }

    const newSmsCode = [...smsCode]
    newSmsCode[index] = value
    setSmsCode(newSmsCode)

    if (value) {
      const currentInput = inputRefs[index]
      const nextInput = inputRefs[index + 1]

      if (nextInput) {
        nextInput.current?.focus()
      } else {
        currentInput.current?.blur()
      }
    }
  }

  const { handleFetch, resetError, error } = useApi<SubmitPhoneResponse>({
    domain: 'submit-phone',

    handleSuccess: (data) => {
      setCommonClientData(data.client)
      setCurrentStep('form')
    },

    handleError: () => {
      setSmsCode([])
      inputOne.current?.focus()
    },
  })

  useEffect(() => {
    if (smsCode.length === 3) {
      handleFetch({
        code: smsCode.join(''),
        phoneNumber: userPhoneNumber,
      })
    }
  }, [smsCode])

  return (
    <>
      <div
        className={classNames(styles.label, {
          [styles.error]: error,
        })}
      >
        {error || 'SMS-код'}
      </div>
      <div className={styles.code} data-testid='sms-code-input'>
        {Array(3)
          .fill(null)
          .map((_, index) => (
            <div className={styles.code__input} key={index}>
              <Input
                autoFocus={index === 0}
                inputMode='numeric'
                maxLength={1}
                type='text'
                value={smsCode[index]}
                onChange={(value) => handleInputChange(index, value)}
                inputRef={inputRefs[index]}
                errorMessage={error}
                inputClassName={styles.codeInput}
              />
            </div>
          ))}
      </div>

      <Timer
        initialSeconds={60}
        refetchCode={() => {
          refetchCode()
          resetError()
        }}
      />
    </>
  )
}
