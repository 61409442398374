import { useState } from 'react'
import { IconGiftSmall } from '../../../icons/IconGiftSmall'
import styles from './GiftBall.module.scss'

type GiftBallProps = {
  className?: string
  value: number
  hasInlineHint?: boolean
}

const TEXT_HINT = 'Получите бонус за заполнение поля'

export const GiftBall: React.FC<GiftBallProps> = ({
  className,
  hasInlineHint,
  value,
}) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div className={className}>
      <span
        onMouseOver={() => setIsActive(true)}
        onMouseLeave={() => setIsActive(false)}
        className={styles.giftball}
      >
        +{value}{' '}
        <div className={styles.giftball__icon}>
          <IconGiftSmall />
        </div>
        {!hasInlineHint && isActive && (
          <div className={styles.giftball__hint}>{TEXT_HINT}</div>
        )}
      </span>

      {hasInlineHint && (
        <span className={styles.giftball__hint_inline}>{TEXT_HINT}</span>
      )}
    </div>
  )
}
