import styles from './Card.module.scss'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { isMobileDevice } from '~/src/utils/isMobileDevice'

type CardProps = {
  img?: string | null
  bgColor: string
}

export const Card: React.FC<CardProps> = ({ bgColor = '#fff', img }) => {
  const cardRef = React.useRef<HTMLDivElement>(null)

  const [listening, setListening] = useState(false)
  const [calculated, setCalculated] = useState(0)
  const [transition, setTransition] = useState(true)

  const isMobile = isMobileDevice()

  const handleScroll = () => {
    const scrollTop = Math.max(
      window.scrollY,
      document.documentElement.scrollTop,
    )

    const maxDeg = 40
    const deg = (maxDeg * scrollTop) / 200

    cardRef.current!.style.transform = `rotate3d(-1, 0, 0, ${1 + deg}deg)`
  }

  useEffect(() => {
    if (isMobile) {
      setCalculated(1)
      setTransition(false)
      window.addEventListener('scroll', handleScroll)
    }
  }, [])

  const handleMouseleave = () => {
    if (isMobile) return

    setListening(false)
    setTransition(true)
    setCalculated(0)
    cardRef.current!.style.transform = ''
  }

  const handleMouseenter = () => {
    if (isMobile) return

    setListening(true)
    setTransition(false)
    setCalculated(0)
  }

  const compute = (event: React.MouseEvent<Element, MouseEvent>) => {
    const { clientX, clientY } = event
    const { top, left, width, height } =
      cardRef.current!.getBoundingClientRect()

    if (calculated < 1) {
      setCalculated(calculated + 0.02)
    }

    return {
      x: (((clientX - left) / width) * 2 - 1) * calculated,
      y: (((clientY - top) / height) * 2 - 1) * calculated,
    }
  }

  const handleMousemove: React.MouseEventHandler = (evt) => {
    if (!listening || isMobile) return
    const { x, y } = compute(evt)

    cardRef.current!.style.transform = `rotate3d(${-y}, ${x}, 0, ${
      4 * calculated
    }deg)`
  }

  return (
    <div className={styles.card}>
      <div
        ref={cardRef}
        className={classNames(styles.card__inner, {
          [styles.transition]: transition,
        })}
        onMouseEnter={handleMouseenter}
        onMouseLeave={handleMouseleave}
        onMouseMove={handleMousemove}
      >
        {!img ? (
          <div
            className={classNames(styles.card__view, styles.card__view_default)}
          />
        ) : (
          <div
            className={styles.card__view}
            style={{ backgroundColor: bgColor }}
          >
            <div
              className={styles.card__img}
              style={{ backgroundImage: `url(${img})` }}
            />
          </div>
        )}

        <div className={styles.card__text}>123456</div>
      </div>
    </div>
  )
}
