import { useState } from 'react'
import { IconApply } from '../../../icons/IconApply'
import { IconApplyLarge } from '../../../icons/IconApplyLarge'
import { IconX } from '../../../icons/IconX'
import { GiftBall } from '../GiftBall/GiftBall'
import styles from './Checkbox.module.scss'
import classNames from 'classnames'

type CheckboxProps = {
  checked: boolean
  disabled?: boolean
  asFormControl?: boolean
  error?: boolean
  notice?: string
  giftBall?: number
  className?: string
  name?: string
  children?: React.ReactNode
  iconType?: 'checkMark' | 'checkMarkLarge' | 'cross'
  onChange?: (checked: boolean) => void
}

const ICONS = {
  checkMark: IconApply,
  checkMarkLarge: IconApplyLarge,
  cross: IconX,
}

export const Checkbox: React.FC<CheckboxProps> = ({
  iconType = 'checkMark',
  asFormControl = false,
  checked = false,
  disabled = false,
  error,
  giftBall,
  notice,
  className,
  children,
  onChange,
  name,
}) => {
  const [isChecked, setIsChecked] = useState(checked)

  const handleChange = () => {
    setIsChecked(!isChecked)
    onChange?.(!isChecked)
  }

  const Icon = ICONS[iconType]

  return (
    <label
      className={classNames(styles.checkbox, className, {
        [styles.disabled]: disabled,
        [styles.error]: error,
        [styles.asFormControl]: asFormControl,
      })}
    >
      <input
        type='checkbox'
        className={styles.checkbox__control}
        checked={isChecked}
        value={isChecked.toString()}
        disabled={disabled}
        onChange={handleChange}
        name={name}
      />
      <div className={styles.checkbox__wrapper}>
        <div
          className={classNames(styles.checkbox__box, {
            [styles.isChecked]: isChecked,
          })}
        >
          <Icon />
        </div>

        <div className={styles.checkbox__label}>
          {children}
          <div className={styles.checkbox__notice}>{notice}</div>
        </div>

        {!!giftBall && !disabled && (
          <GiftBall value={giftBall} className={styles.checkbox__gift} />
        )}
      </div>
    </label>
  )
}
