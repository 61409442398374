import React from 'react'
import styles from './ClientForm.module.scss'
import { Autocomplete } from '../ui/Autocomplete/Autocomplete'
import { fetchSuggestions } from '../../dadata'
import { Children, Field } from '../../types'
import { Checkbox } from '../ui/Checkbox/Checkbox'
import { GiftBall } from '../ui/GiftBall/GiftBall'
import { ChildrenInput } from './ChildrenInput/ChildrenInput'
import { Input } from '../ui/Input/Input'
import { InputMask } from '../ui/InputMask/InputMask'
import { validateDate } from '../../utils/validateDate'

type FieldComponentProps = {
  field: Field
  defaultValues?: Record<string, string>
  defaultChildren?: Children[]
  errorMessage?: string
  dadataApiKey?: string
}

const GENDERS = ['Мужской', 'Женский']

const SELECT_FIELDS = ['gender', 'static-set', 'level']
const DADATA_AUTOCOMPLETE_FIELDS = [
  'fullName',
  'name',
  'surname',
  'patronymic',
  'email',
]

export const FieldComponent: React.FC<FieldComponentProps> = ({
  field,
  defaultValues,
  defaultChildren,
  errorMessage,
  dadataApiKey,
}) => {
  if (field.type === 'children') {
    return (
      <>
        <ChildrenInput
          dadataApiKey={dadataApiKey}
          defaultValue={defaultChildren}
        />

        {!!field.bonus?.amount && (
          <GiftBall
            className={styles.childrenGiftBall}
            value={field.bonus?.amount}
            hasInlineHint
          />
        )}
      </>
    )
  }

  if (SELECT_FIELDS.includes(field.type)) {
    const defaultValue =
      field.type === 'gender'
        ? defaultValues?.[field.code]
          ? GENDERS[+defaultValues?.[field.code] - 1]
          : GENDERS[+field.defaultValue]
        : defaultValues?.[field.code] || field.defaultValue

    return (
      <Autocomplete
        formValue={field.type === 'gender' ? 'key' : 'text'}
        isRequired={field.required}
        isDisabled={
          field.type === 'gender'
            ? defaultValues?.[field.code] !== undefined
            : !!defaultValues?.[field.code]
        }
        name={field.code}
        label={field.title}
        items={field.type === 'gender' ? GENDERS : field.options}
        defaultValue={defaultValue}
        defaultInputValue={defaultValue}
        errorMessage={errorMessage}
        giftBall={field.bonus?.amount}
      />
    )
  }

  if (DADATA_AUTOCOMPLETE_FIELDS.includes(field.code)) {
    return (
      <Autocomplete
        isRequired={field.required}
        isDisabled={!!defaultValues?.[field.code]}
        defaultInputValue={defaultValues?.[field.code] || field.defaultValue}
        fetchOptions={(query) =>
          fetchSuggestions(dadataApiKey, field.code, query)
        }
        name={field.code}
        label={field.title}
        giftBall={field.bonus?.amount}
      />
    )
  }

  if (field.type === 'checkbox') {
    const checked =
      defaultValues?.[field.code] === 'Yes' || field.defaultValue === 'Yes'

    return (
      <Checkbox
        checked={checked}
        name={field.code}
        asFormControl
        disabled={checked}
        iconType='checkMarkLarge'
        notice={field.notice}
        giftBall={field.bonus?.amount}
      >
        <div className={styles.checkboxTitle}>{field.title}</div>
      </Checkbox>
    )
  }

  if (field.type === 'subscribed') {
    const checked = !!defaultValues?.[field.code]

    return (
      <Checkbox
        checked={checked}
        name={field.code}
        asFormControl
        disabled={checked}
        iconType='checkMarkLarge'
        notice={field.notice}
        giftBall={field.bonus?.amount}
      >
        <div className={styles.checkboxTitle}>{field.title}</div>
      </Checkbox>
    )
  }

  if (field.code === 'birthdate') {
    return (
      <InputMask
        isDisabled={!!defaultValues?.[field.code]}
        name={field.code}
        label={field.title}
        inputMode={'numeric'}
        type={field.type}
        defaultValue={defaultValues?.[field.code] || field.defaultValue}
        giftBall={field.bonus?.amount}
        mask={'99.99.9999'}
        placeholder={'01.01.1900'}
        isRequired={field.required}
        onValid={(value) => (validateDate(value) ? '' : 'Неверная дата')}
      />
    )
  }

  return (
    <Input
      name={field.code}
      label={field.title}
      inputMode={
        field.type === 'numeric' && field.code !== 'cardText'
          ? 'numeric'
          : 'text'
      }
      type={field.type}
      pattern={
        field.type === 'numeric' && field.code !== 'cardText'
          ? '[0-9]*'
          : undefined
      }
      defaultValue={defaultValues?.[field.code] || field.defaultValue}
      giftBall={field.bonus?.amount}
      isRequired={field.required}
      isDisabled={!!defaultValues?.[field.code]}
    />
  )
}
