export const IconApplyLarge = () => (
  <svg
    width='22'
    height='16'
    viewBox='0 0 22 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M2 6.4L8.75 13L20 2' stroke='currentColor' strokeWidth='3' />
  </svg>
)
