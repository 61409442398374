import { useEffect } from 'react'

export const useValidateClientForm = (handleError: () => void) => {
  useEffect(() => {
    const clientForm = document?.getElementById('clientForm')

    clientForm?.addEventListener('submit', handleError)

    return () => {
      clientForm?.removeEventListener('submit', handleError)
    }
  }, [handleError])
}
