import { useCallback, useState } from 'react'
import { errorMap } from './utils/error'

import axios, { AxiosError } from 'axios'
import { useStepsContext } from './stepsContext'
import { Error } from './types'

// Для работы с локальным беком в режиме разработки поставить - /api
// Для работы с беком на тестовом стенде - https://form-test.maxma.com/
const baseURL =
  process.env.NODE_ENV === 'production' ? '/' : 'https://form-test.maxma.com/'

const app = axios.create({
  baseURL,
  withCredentials: true,
})

type HandleFetch<T> = {
  only500?: boolean
  isErrorPageRedirect?: boolean
  domain: string
  handleSuccess?: (data: T) => void
  handleError?: (error: Error) => void
  handleFinally?: () => void
}

export function useApi<T = Record<string, unknown>>({
  isErrorPageRedirect = false,
  only500 = false,
  domain,
  handleError,
  handleFinally,
  handleSuccess,
}: HandleFetch<T>) {
  const [error, setError] = useState<string | boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<T | null>(null)

  const { setRequestError, setIsChangePhoneNumber, setCurrentStep } =
    useStepsContext()

  const resetError = () => {
    setError(false)
  }

  const handleFetch = useCallback(
    (body?: Record<string, unknown>, token?: string) => {
      setError(false)
      setIsLoading(true)
      setData(null)

      const isProd = process.env.NODE_ENV === 'production'

      const headers: Record<string, string | undefined> = {
        'Content-Type': 'application/json',
      }

      if (token) {
        headers['X-ReCaptcha-Response'] = isProd ? token : 'dev-token'
      }

      app
        .post<T>(domain, body, {
          headers,
        })

        .catch((error: AxiosError<Error>) => {
          const errorCode =
            errorMap[error?.response?.data.error?.code as keyof typeof errorMap]

          if (
            (isErrorPageRedirect || only500) &&
            error.status &&
            (only500 ? error.status >= 500 : error.status >= 400)
          ) {
            setRequestError({
              code: errorCode,
              is400: error.status >= 400 && error.status < 500,
              is500: error.status >= 500,
            })
            setIsChangePhoneNumber(true)
            setCurrentStep('error')

            return
          }

          if (error.response && error.response.data.error) {
            setError(errorCode)
          }

          handleError?.(error.response!.data)
        })
        .then((response) => {
          if (response?.data) {
            handleSuccess?.(response?.data)
            setData(response?.data)
          }
        })

        .finally(() => {
          setIsLoading(false)
          handleFinally?.()
        })
    },
    [],
  )

  return {
    isLoading,
    error,
    data,
    handleFetch,
    resetError,
  }
}
