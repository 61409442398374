import classNames from 'classnames'
import { IconTelegram } from '../../icons/IconTelegram'
import { IconWhatsapp } from '../../icons/IconWhatsapp'

import styles from './AuthForm.module.scss'

type TryByMessengersProps = {
  verifyByTelegram: boolean
  verifyByWhatsapp: boolean
}

export const TryByMessengers: React.FC<TryByMessengersProps> = ({
  verifyByTelegram,
  verifyByWhatsapp,
}) => {
  if (verifyByTelegram || verifyByWhatsapp)
    return (
      <>
        {verifyByTelegram && (
          <a
            className={classNames(styles.link, styles.social__icon)}
            href='/verify-phone?by=telegram'
          >
            <IconTelegram /> Telegram
          </a>
        )}

        {verifyByWhatsapp && (
          <>
            {verifyByTelegram && <span>или</span>}
            <a
              className={classNames(styles.link, styles.social__icon)}
              href='/verify-phone?by=whatsapp'
            >
              <IconWhatsapp /> WhatsApp
            </a>
          </>
        )}
      </>
    )
}
