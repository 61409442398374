import { InnerPage } from '../../InnerPage/InnerPage'
import styles from './PageLoader.module.scss'

export const PageLoader = () => {
  return (
    <InnerPage title='Загрузка...'>
      <div className={styles.loaderContainer}>
        <div className={styles.loader} />
      </div>
    </InnerPage>
  )
}
