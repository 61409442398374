import { useCallback, useState } from 'react'
import classNames from 'classnames'

import styles from './Input.module.scss'
import { type AriaTextFieldProps } from '@react-aria/textfield'
import { GiftBall } from '../GiftBall/GiftBall'

import { Label, TextField, Input as AriaInput } from 'react-aria-components'
import { useValidateClientForm } from '../../ClientForm/useValidateClientForm'
type InputProps = AriaTextFieldProps & {
  isDate?: boolean
  withChange?: boolean
  placeholder?: string
  className?: string
  inputClassName?: string
  label?: string
  giftBall?: number
  mask?: string
  inputRef?: React.RefObject<HTMLInputElement>
  children?: React.ReactNode
  onChange?: (value: string) => void
  handleClickChange?: () => void
  onValid?: (value: string) => string
}

export const Input: React.FC<InputProps> = ({
  withChange = false,
  label,
  className,
  inputClassName,
  handleClickChange,
  giftBall,
  onValid,
  onChange,
  inputRef,
  errorMessage,
  ...props
}) => {
  const [query, setQuery] = useState(props.defaultValue || '')

  const [focus, setFocus] = useState(false)

  const inputLabel = label && props.isRequired ? `${label}*` : label

  const [error, setError] = useState('')

  const isError = !!error

  const handleError = useCallback(() => {
    if (!query && props.isRequired) {
      setError('Обязательно')
      return
    }

    if (query && onValid) {
      setError(onValid?.(query))
    }
  }, [onValid, props, query])

  useValidateClientForm(handleError)

  return (
    <TextField
      {...props}
      className={classNames(styles.input, className, 'textField', {
        [styles.disabled]: props.isDisabled,
      })}
    >
      {label && (
        <Label
          className={classNames(styles.input__label, {
            [styles.error]: !!isError,
          })}
        >
          {label && !!isError ? label + ' . ' : inputLabel}

          {error}
        </Label>
      )}

      <div
        className={classNames(styles.input__wrapper, {
          [styles.focus]: focus,
          [styles.error]: isError || !!errorMessage,
        })}
      >
        <div className={styles.input__part}>
          <AriaInput
            ref={inputRef}
            className={classNames(styles.input__control, inputClassName, {
              [styles.disabled]: props.isDisabled,
            })}
            value={props.value || query}
            onChange={(e) => {
              if (onChange) onChange?.(e.target.value)
              else setQuery(e.target.value)
              setError('')
            }}
            onFocus={() => {
              setFocus(true)
            }}
            onBlur={() => {
              setFocus(false)
              handleError()
            }}
          />
        </div>

        {!!giftBall && !props.isDisabled && (
          <GiftBall
            value={giftBall}
            className={classNames(styles.input__part, styles.input__part_gift)}
          />
        )}

        {withChange && (
          <div
            className={classNames(styles.input__part, styles.input__change)}
            onClick={handleClickChange}
          >
            <span className='link'>Изменить</span>
          </div>
        )}
      </div>
    </TextField>
  )
}
