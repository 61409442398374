export const IconGiftSmall = () => (
  <svg
    width='15'
    height='16'
    viewBox='0 0 15 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.3029 3.612H11.795C12.0085 3.23782 12.1313 2.79453 12.1313 2.3207C12.1313 0.996363 11.1667 -0.1 9.95821 -0.1C9.60002 -0.1 9.28394 -0.0280358 9.00367 0.124316C8.72361 0.276555 8.48494 0.505998 8.27685 0.812729L8.27681 0.812785C7.96792 1.26877 7.73527 1.8831 7.46926 2.63201C7.20375 1.88309 6.97112 1.26878 6.66222 0.812785C6.45447 0.506029 6.21597 0.27657 5.93598 0.124322C5.65579 -0.0280371 5.33971 -0.1 4.98151 -0.1C3.77237 -0.1 2.80778 0.996319 2.80778 2.32C2.80778 2.79386 2.93053 3.23769 3.14354 3.612H1.63543C0.883956 3.612 0.291602 4.2924 0.291602 5.1047V6.0327C0.291602 6.64883 0.630202 7.18394 1.12082 7.41054V14.4473C1.12082 15.2591 1.71403 15.9386 2.46534 15.9386H12.4737C13.225 15.9386 13.8182 15.2591 13.8182 14.4473V7.41058C14.3083 7.18445 14.6474 6.64806 14.6474 6.0327V5.1047C14.6474 4.29236 14.055 3.612 13.3029 3.612ZM8.32522 3.33481L8.32523 3.33479C8.64448 2.42844 8.85937 1.84928 9.09674 1.49418C9.21353 1.31946 9.33203 1.20518 9.46572 1.13341C9.59931 1.06169 9.75632 1.028 9.95751 1.028C10.5786 1.028 11.102 1.59707 11.102 2.32C11.102 3.0436 10.5787 3.612 9.95751 3.612H8.2275L8.32522 3.33481ZM6.6138 3.33617L6.61386 3.33635L6.712 3.61339H4.98151C4.36043 3.61339 3.83699 3.04436 3.83699 2.3207C3.83699 1.59776 4.3604 1.0287 4.98151 1.0287C5.18271 1.0287 5.33972 1.06239 5.47331 1.13411C5.60699 1.20589 5.72549 1.3202 5.84229 1.49496C6.07966 1.85014 6.29455 2.42947 6.6138 3.33617ZM12.789 7.5247V14.4059L12.7883 14.4066V14.448C12.7883 14.6597 12.637 14.812 12.4737 14.812H9.22899V7.5247H12.789ZM5.71073 14.8113H2.46465C2.30192 14.8113 2.15073 14.6596 2.15073 14.448V7.5247H5.71073V14.8113ZM5.71073 6.39739H1.63543C1.47278 6.39739 1.32082 6.24436 1.32082 6.03339V5.1047C1.32082 4.89314 1.47268 4.7407 1.63543 4.7407H5.71073V6.39739ZM8.19908 14.812H6.73995V4.74H8.19908V14.812ZM13.6182 6.03339C13.6182 6.24483 13.4664 6.39739 13.3029 6.39739H9.22899V4.74H13.3029C13.467 4.74 13.6182 4.89244 13.6182 5.104V6.032V6.03339Z'
      fill='white'
      stroke='white'
      strokeWidth='0.2'
    />
  </svg>
)
