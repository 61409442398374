import {
  Button,
  ComboBox,
  type ComboBoxProps,
  Input,
  Key,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  PopoverRenderProps,
} from 'react-aria-components'
import { debounce } from 'lodash'

import styles from '../Input/Input.module.scss'
import stylesAutocomplete from './Autocomplete.module.scss'
import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { ListBoxItemType, SuggestionsResponse } from '../../../types'
import { IconArrowDropdown } from '../../../icons/IconArrowDropdown'
import { useValidateClientForm } from '../../ClientForm/useValidateClientForm'
import { GiftBall } from '../GiftBall/GiftBall'
import { isMobileDevice } from '~/src/utils/isMobileDevice'

type AutocompleteProps = Omit<
  ComboBoxProps<ListBoxItemType>,
  'children' | 'items'
> & {
  giftBall?: number
  width?: number
  errorMessage?: string
  label?: string
  defaultValue?: string
  items?: string[] | null
  fetchOptions?: (query: string) => Promise<SuggestionsResponse>
  onChange?: (value: string) => void
}

export function Autocomplete({
  label,
  errorMessage,
  items,
  defaultValue,
  giftBall,
  fetchOptions,
  onInputChange,
  ...props
}: AutocompleteProps) {
  const [query, setQuery] = useState(props.defaultInputValue || '')

  const [focus, setFocus] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [popoverPlacement, setPopoverPlacement] = useState<
    PopoverRenderProps['placement'] | undefined
  >(undefined)

  const selectItems = items?.map((item, index) => ({
    id: index + 1,
    value: item.trim(),
  }))

  const defaultSelectedKey = selectItems?.find(
    (item) => item.value === defaultValue,
  )

  const [options, setOptions] = useState<ListBoxItemType[]>([])

  const [selectedKey, setSelectedKey] = React.useState<Key | null>(
    defaultSelectedKey ? +defaultSelectedKey.id : null,
  )

  const onSelectionChange = (id: Key | null) => {
    setSelectedKey(id)
    setError('')
  }

  const [error, setError] = useState(errorMessage || '')

  const handleFetchOptions = debounce((inputValue: string) => {
    fetchOptions?.(inputValue).then((data) => {
      setOptions(
        data.suggestions.map((item, index) => ({
          id: index + item.value,
          value: item.value,
        })),
      )
    })
  }, 200)

  useEffect(() => {
    if (!props.isRequired && !errorMessage) {
      setError('')
    }

    if (errorMessage) {
      setError(errorMessage)
    }
  }, [props.isRequired, errorMessage])

  const isError = !!error
  const isSelect = !!selectItems?.length

  const handleError = useCallback(() => {
    if ((isSelect ? !selectedKey : !query) && props.isRequired) {
      setError('Обязательно')
    }
  }, [props, query, isSelect, selectedKey])

  useValidateClientForm(handleError)

  const inputLabel = label && props.isRequired ? `${label}*` : label
  return (
    <ComboBox
      onOpenChange={(open) => setIsOpen(open)}
      defaultItems={selectItems}
      allowsCustomValue={!isSelect}
      menuTrigger={isSelect ? 'focus' : undefined}
      className={classNames(stylesAutocomplete.autocomplete, {
        [styles.disabled]: props.isDisabled,
      })}
      selectedKey={selectedKey}
      onSelectionChange={onSelectionChange}
      items={options.length ? options : undefined}
      allowsEmptyCollection
      {...props}
    >
      {label && (
        <Label
          className={classNames(styles.input__label, {
            [styles.error]: !!isError,
          })}
        >
          {label && !!isError ? label + '. ' : inputLabel}

          {error}
        </Label>
      )}

      <div
        className={classNames(
          styles.input__part,
          styles.input__wrapper,
          stylesAutocomplete.inputWrapper,
          {
            [styles.focus]: focus,
            [styles.error]: isError,
            [stylesAutocomplete.popoverTop]:
              isOpen && popoverPlacement === 'top',
            [stylesAutocomplete.popoverBottom]:
              isOpen && popoverPlacement === 'bottom',
          },
        )}
      >
        <Input
          aria-invalid={isError}
          className={styles.input__control}
          onChange={(e) => {
            onInputChange?.(e.target.value)
            setQuery(e.target.value)

            if (fetchOptions) {
              handleFetchOptions(e.target.value)
            }
            setError('')
          }}
          onFocus={() => {
            setFocus(true)
          }}
          onBlur={() => {
            if (isSelect) setQuery(props.defaultInputValue || '')
            setFocus(false)
            handleError()
          }}
        />

        {isSelect && (
          <Button
            className={classNames(stylesAutocomplete.openPopoverButton, {
              [styles.error]: isError,
            })}
          >
            <IconArrowDropdown />
          </Button>
        )}

        {!!giftBall && !props.isDisabled && (
          <Button
            className={stylesAutocomplete.giftBallContaner}
            excludeFromTabOrder
            isDisabled
          >
            <GiftBall
              value={giftBall}
              className={classNames(
                styles.input__part,
                styles.input__part_gift,
              )}
            />
          </Button>
        )}
      </div>

      <Popover
        crossOffset={-1}
        className={({ placement }) => {
          setPopoverPlacement(placement)

          return classNames(stylesAutocomplete.optionsPopover, {
            [stylesAutocomplete.error]: isError,
          })
        }}
        offset={-1}
        maxHeight={isMobileDevice() ? 150 : 200}
      >
        <ListBox
          renderEmptyState={() => (
            <div className={stylesAutocomplete.optionsEmpty}>
              Ничего не найдено
            </div>
          )}
        >
          {(item: ListBoxItemType) => (
            <ListBoxItem
              id={item.id}
              className={({ isFocused }) =>
                classNames(stylesAutocomplete.optionsItem, {
                  [stylesAutocomplete.focused]: isFocused,
                })
              }
            >
              {item.value}
            </ListBoxItem>
          )}
        </ListBox>
      </Popover>
    </ComboBox>
  )
}
