import { InnerPage } from '~/src/components/InnerPage/InnerPage'
import styles from './MainPage.module.scss'
import { useStepsContext } from '~/src/stepsContext'
import { Layout } from '~/src/components/Layout/Layout'
import { FormRouter } from '~/src/components/FormRouter/FormRouter'
import { ErrorPage } from '~/src/pages/ErrorPage/ErrorPage'
import { ThankyouPage } from '~/src/pages/ThankyouPage/ThankyouPage'
import { QRCodePage } from '~/src/pages/QRCodePage/QRCodePage'
import { isMobileDevice } from '~/src/utils/isMobileDevice'

export const MainPage: React.FC = () => {
  const { currentStep, walletsLink } = useStepsContext()

  const isGetCard = currentStep === 'start' || currentStep === 'otp'

  if (currentStep === 'error') return <ErrorPage />

  if (currentStep === 'thankyou') {
    if (!walletsLink) return <ThankyouPage />

    if (isMobileDevice()) {
      setTimeout(() => {
        document.location.href = walletsLink as string
      }, 1000)
      return <ThankyouPage />
    } else {
      return <QRCodePage />
    }
  }

  if (currentStep === 'qr') return <QRCodePage />

  return (
    <Layout withCard>
      <InnerPage
        title={
          isGetCard ? 'Получить карту' : 'Добро пожаловать!\nДозаполните поля'
        }
      >
        <div className={styles.formContainer}>
          <FormRouter />
        </div>
      </InnerPage>
    </Layout>
  )
}
