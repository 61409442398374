import { useStepsContext } from '~/src/stepsContext'

import styles from './ThankyouPage.module.scss'
import { Layout } from '~/src/components/Layout/Layout'
import { InnerPage } from '~/src/components/InnerPage/InnerPage'
import { LayoutCard } from '~/src/components/LayoutCard/LayoutCard'

type ThankyouPageProps = {
  code?: string
  is400?: boolean
  is500?: boolean
  handleClick?: () => void
}

export const ThankyouPage: React.FC<ThankyouPageProps> = () => {
  const { walletsLink = null } = useStepsContext()

  return (
    <Layout back fullHeight>
      <InnerPage
        containerClassName={styles.container}
        center
        title='Ваша карта готова!'
        subTitle={
          walletsLink
            ? 'Загрузка произойдет\nчерез несколько секунд.'
            : 'Используйте ваш номер телефона\nв качестве номера карты.'
        }
      >
        <LayoutCard />
      </InnerPage>
    </Layout>
  )
}
