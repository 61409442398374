import React, { useState, useEffect } from 'react'
import styles from './SendCode.module.scss'
import classNames from 'classnames'

type TimerProps = {
  initialSeconds: number
  refetchCode: () => void
}

const Timer: React.FC<TimerProps> = ({ initialSeconds, refetchCode }) => {
  const [seconds, setSeconds] = useState(initialSeconds)

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1
        } else {
          clearInterval(interval)
          return 0
        }
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [seconds])

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, '0')
    const seconds = (time % 60).toString().padStart(2, '0')

    return `${minutes}:${seconds}`
  }

  return (
    <div className={styles.timer}>
      {seconds ? (
        `Запросить новый код можно через ${formatTime(seconds)}`
      ) : (
        <div
          className={classNames(styles['timeout-reset'], 'link')}
          onClick={() => {
            refetchCode()
            setSeconds(initialSeconds)
          }}
        >
          Запросить новый код
        </div>
      )}
    </div>
  )
}

export default Timer
