import { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'

import styles from './InputMask.module.scss'
import ReactInputMask from 'react-input-mask'
import { type AriaTextFieldProps } from '@react-aria/textfield'
import { GiftBall } from '../GiftBall/GiftBall'

import { Label, TextField } from 'react-aria-components'
import { useValidateClientForm } from '../../ClientForm/useValidateClientForm'
export type InputProps = AriaTextFieldProps & {
  withChange?: boolean
  isValidNumber?: boolean
  placeholder?: string
  label?: string
  giftBall?: number
  mask: string
  inputRef?: React.RefObject<HTMLInputElement>
  children?: React.ReactNode
  onChange?: (value: string) => void
  onValid?: (value: string) => string
  handleClickChange?: () => void
}

export const InputMask: React.FC<InputProps> = ({
  withChange = false,
  mask,
  label,
  handleClickChange,
  giftBall,
  onChange,
  onValid,
  isDisabled,
  ...props
}) => {
  const [query, setQuery] = useState(props.value || props.defaultValue)

  const [focus, setFocus] = useState(false)
  const [error, setError] = useState('')

  const inputLabel = label && props.isRequired ? `${label}*` : label

  const placeholder = props.placeholder || ''

  const maskPlaceholder =
    query + placeholder.slice(((query as string) || '').length)

  useEffect(() => {
    if (!props.value && !props.defaultValue) setQuery('')
  }, [])

  const isError = !!error

  const handleError = useCallback(() => {
    if (!query && props.isRequired) {
      setError('Обязательно')
      return
    }

    if (query && onValid) {
      setError(onValid?.(query))
    }
  }, [onValid, props, query])

  useValidateClientForm(handleError)

  useEffect(() => {
    if (!props.isRequired) {
      setError('')
    }
  }, [props.isRequired])

  return (
    <TextField
      className={classNames(styles.input, {
        [styles.disabled]: isDisabled,
      })}
    >
      {label && (
        <Label
          className={classNames(styles.input__label, {
            [styles.error]: !!isError,
          })}
        >
          {label && !!isError ? label + '. ' : inputLabel}

          {error}
        </Label>
      )}

      <div
        className={classNames(styles.input__wrapper, {
          [styles.focus]: focus && !isDisabled,
          [styles.error]: !!isError,
        })}
      >
        <div className={styles.input__part}>
          {!isDisabled && (
            <input
              placeholder={maskPlaceholder}
              tabIndex={-1}
              className={classNames(styles.input__control, styles.place)}
              readOnly
              data-hidden='true'
              disabled={isDisabled}
            />
          )}

          {/* @ts-ignore */}
          <ReactInputMask
            disabled={isDisabled}
            aria-invalid={isError}
            alwaysShowMask
            maskPlaceholder={null}
            mask={mask}
            className={classNames(styles.input__control, {
              [styles.disabled]: isDisabled,
            })}
            {...props}
            onChange={(e) => {
              setQuery(e.target.value)
              onChange?.(e.target.value)
              setError('')
            }}
            onFocus={() => {
              setFocus(true)
            }}
            onBlur={() => {
              setFocus(false)
              handleError()
            }}
          />
        </div>

        {!!giftBall && !isDisabled && (
          <GiftBall
            value={giftBall}
            className={classNames(styles.input__part, styles.input__part_gift)}
          />
        )}

        {withChange && (
          <div
            className={classNames(styles.input__part, styles.input__change)}
            onClick={() => {
              handleClickChange?.()
              setQuery('')
            }}
          >
            <span className='link'>Изменить</span>
          </div>
        )}
      </div>
    </TextField>
  )
}
