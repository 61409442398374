import { useStepsContext } from '~/src/stepsContext'
import { Button } from '../ui/Button/Button'
import { Checkbox } from '../ui/Checkbox/Checkbox'
import styles from './CommunicationTypes.module.scss'

export const CommunicationTypes: React.FC = () => {
  const { agreedWithTerms, brand, setAgreedWithTerms, handleChangeStep } =
    useStepsContext()

  return (
    <div className={styles.container}>
      {brand?.verifyMethods?.map((method) => {
        if (method === 'telegram') {
          return (
            <Button
              variant='telegram'
              title='ЧЕРЕЗ ТЕЛЕГРАМ'
              disabled={!agreedWithTerms}
              href='/verify-phone?by=telegram'
            />
          )
        }

        if (method === 'whatsapp') {
          return (
            <Button
              href='/verify-phone?by=whatsapp'
              variant='whatsapp'
              title='ЧЕРЕЗ WHATSAPP'
              disabled={!agreedWithTerms}
            />
          )
        }

        if (method === 'sms') {
          return (
            <Button
              onClick={() => handleChangeStep('otp')}
              variant='sms'
              title='ЧЕРЕЗ SMS'
              disabled={!agreedWithTerms}
            />
          )
        }
      })}

      <Checkbox
        giftBall={0}
        checked={agreedWithTerms}
        onChange={() => setAgreedWithTerms(!agreedWithTerms)}
      >
        {brand?.termsLink ? (
          <span dangerouslySetInnerHTML={{ __html: brand.termsLink }} />
        ) : (
          <span style={{ color: '#ff6767' }}>
            Текст требует согласования с партнером
          </span>
        )}
      </Checkbox>
    </div>
  )
}
