import React, { createContext, useCallback, useContext, useState } from 'react'
import {
  Field,
  Steps,
  Brand,
  StepsContextType,
  RequestError,
  Client,
} from './types'

const StepsContext = createContext<StepsContextType>({
  isChangePhoneNumber: false,
  isInitRequestLoading: false,
  agreedWithTerms: true,
  phoneMask: '',
  fields: [],
  commonClientData: undefined,
  userPhoneNumber: '',
  requestError: {
    code: '',
    is400: false,
    is500: false,
  },
  brand: {
    phoneMask: '',
    name: null,
    logo: '',
    cardColor: '',
    brandName: '',
    termsLink: '',
    codeAdapter: '',
    verifyMethods: [],
    phoneNumberVerified: false,
    dadataApiKey: '',
  },
  walletsLink: '',
  verifyByTelegram: false,
  verifyByWhatsapp: false,
  currentStep: 'start',
  handleChangeStep: () => {},
  setAgreedWithTerms: () => {},
  setWalletsLink: () => {},
  setCurrentStep: () => {},
  setBrand: () => {},
  setCommonClientData: () => {},
  setFields: () => {},
  setUserPhoneNumber: () => {},
  setRequestError: () => {},
  setIsChangePhoneNumber: () => {},
  setIsInitRequestLoading: () => {},
})

export const StepsContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [isInitRequestLoading, setIsInitRequestLoading] = useState(false)
  const [isChangePhoneNumber, setIsChangePhoneNumber] = useState(false)
  const [brand, setBrand] = useState<Brand | null>(null)
  const [commonClientData, setCommonClientData] = useState<Client | undefined>(
    undefined,
  )
  const [userPhoneNumber, setUserPhoneNumber] = useState('')

  const [walletsLink, setWalletsLink] = useState<string | null>(null)
  const [agreedWithTerms, setAgreedWithTerms] = useState(true)
  const [fields, setFields] = useState<Field[]>([])
  const [requestError, setRequestError] = useState<RequestError>({
    code: '',
    is400: false,
    is500: false,
  })

  const [currentStep, setCurrentStep] = useState<Steps>('start')

  const handleChangeStep = useCallback((step: Steps) => {
    setCurrentStep(step)
  }, [])

  const verifyByTelegram = brand?.verifyMethods?.includes('telegram') || false
  const verifyByWhatsapp = brand?.verifyMethods?.includes('whatsapp') || false

  return (
    <StepsContext.Provider
      value={{
        brand,
        fields, // массив полей для формы клиента
        walletsLink,
        currentStep, // текущий шаг
        requestError, // ошибка запроса
        agreedWithTerms,
        userPhoneNumber,
        commonClientData, // данные клиента из поля client
        verifyByWhatsapp,
        verifyByTelegram,
        isChangePhoneNumber, // флаг изменения номера для перехода на otp безе ожидания смс
        isInitRequestLoading, // флаг ожидания ответа /sess

        setBrand,
        setFields,
        setWalletsLink,
        setCurrentStep,
        setRequestError,
        handleChangeStep,
        setAgreedWithTerms,
        setUserPhoneNumber,
        setCommonClientData,
        setIsChangePhoneNumber,
        setIsInitRequestLoading,
      }}
    >
      {children}
    </StepsContext.Provider>
  )
}

export const useStepsContext = (): StepsContextType => {
  return useContext(StepsContext)
}
