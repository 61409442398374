import { useStepsContext } from '~/src/stepsContext'
import { Card } from '../Card/Card'
import styles from './LayoutCard.module.scss'

export const LayoutCard: React.FC = () => {
  const { brand } = useStepsContext()

  return (
    <div className={styles['layout-card']}>
      <div className={styles['layout-card__preview']}>
        <Card img={brand?.logo} bgColor={brand?.cardColor || ''} />
      </div>
      <div className={styles['layout-card__bg']} />
    </div>
  )
}
