export const IconArrowDropdown = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19'
    height='11'
    viewBox='0 0 19 11'
  >
    <path
      fill='#346AF0'
      fillRule='nonzero'
      d='M9.068 9.938l-7.77-7.772A1.265 1.265 0 1 1 3.086.376l6.876 6.877L16.84.376a1.266 1.266 0 0 1 1.79 1.79l-7.771 7.772c-.247.247-.571.37-.895.37-.324 0-.648-.123-.895-.37z'
    />
  </svg>
)
