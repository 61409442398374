export const errorMap: Record<number | string, string> = {
  // start
  ErrFormNotFound: 'Страница не найдена',
  1: 'Страница не найдена',

  // send-code
  // submit-phone
  // submit-client
  // pass
  //(это когда ты пытаешься сделать запрос, не сделав /start, или сессия протухла)"
  ErrSessionNotStarted: 'Сессия истекла, перезагрузите страницу',
  2: 'Сессия истекла, перезагрузите страницу',

  // send-code
  ErrNotAgreedWithTerms: 'Вы не согласились с условиями',
  3: 'Вы не согласились с условиями',

  // send-code
  // submit-phone
  ErrIncorrectPhone: 'Неверный номер телефона',
  4: 'Неверный номер телефона',

  // send-code
  ErrTooManyCodeRequests: 'Слишком частая отправка кода',
  5: 'Слишком частая отправка кода',

  // submit-phone
  ErrPhonesMismatch: 'Телефоны не совпадают',
  ErrCodesMismatch: 'Неверный код',
  ErrCodeExpired: 'Срок действия кода истёк',

  6: 'Телефоны не совпадают',
  7: 'Неверный код',
  8: 'Срок действия кода истёк',

  // submit-client
  ErrPhoneNotVerified: 'Телефон не подтвержден',
  ErrValidationFailed: 'Форма заполнена неверно',
  ErrServerError: 'Не удалось отправить запрос, повторите попытку позднее',

  9: 'Телефон не подтвержден',
  10: 'Форма заполнена неверно',
  11: 'Не удалось отправить запрос, повторите попытку позднее',
  12: 'Ошибка проверки браузера',

  // field-level errors
  ErrFieldRequired: 'Поле обязательно к заполнению',
  ErrFieldInvalid: 'Поле заполнено неверно',
  ErrFieldDuplicating: 'Клиент с таким номером уже существует',

  100: 'Поле обязательно к заполнению',
  101: 'Поле заполнено неверно',
  102: 'Клиент с таким номером уже существует',
}
