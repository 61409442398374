import { useStepsContext } from '~/src/stepsContext'

import styles from './ErrorPage.module.scss'
import { IconError500 } from '~/src/icons/IconError500'
import { Layout } from '~/src/components/Layout/Layout'
import { InnerPage } from '~/src/components/InnerPage/InnerPage'
import { Button } from '~/src/components/ui/Button/Button'

export const ErrorPage: React.FC = () => {
  const { requestError, setCurrentStep } = useStepsContext()

  const { code, is400, is500 } = requestError

  return (
    <Layout fullHeight>
      <InnerPage containerClassName={styles.innerCantainer} center>
        <div className={styles['request-error']}>
          <div className={styles['request-error__img']}>
            <div className={styles['request-error__icon']}>
              <IconError500 />
            </div>
            <div className={styles['request-error__bg']} />
          </div>
          <div className={styles['request-error__text']}>
            {code ? (
              <span className={styles['request-error__code']}>{code}</span>
            ) : (
              <>
                {is400 && <span>Вы перешли по неверной ссылке</span>}
                {is500 && (
                  <span>{'Не удалось отправить\nзапрос на сервер'}</span>
                )}
              </>
            )}
          </div>

          {is500 && (
            <div className={styles['request-error__back']}>
              <Button
                title='ПОВТОРИТЬ ЗАПРОС'
                onClick={() => setCurrentStep('start')}
              />
            </div>
          )}
        </div>
      </InnerPage>
    </Layout>
  )
}
