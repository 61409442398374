import moment from 'moment'

export const validateDate = (date: string) => {
  if (!date || date.length !== 10) {
    return false
  }

  const mDate = moment(date, 'DD.MM.YYYY')

  return mDate.isValid()
}
