import { useParams, Outlet } from 'react-router-dom'
import { useEffect } from 'react'
import { useApi } from '../api'
import { StartResponse } from '../types'
import { useStepsContext } from '../stepsContext'

const DEFAULT_CODE_ADAPTER = 'sms'

const DEFAULT_VERIFY_METHODS = ['telegram', 'whatsapp', 'sms']

const DEFAULT_MASK = '+7 (###) ###-##-##'

export const OutletComponent = () => {
  const {
    setBrand,
    setFields,
    setWalletsLink,
    setCurrentStep,
    setUserPhoneNumber,
    setCommonClientData,
    setIsInitRequestLoading,
  } = useStepsContext()

  const { query } = useParams()

  const { handleFetch } = useApi<StartResponse>({
    isErrorPageRedirect: true,
    domain: 'sess',
    handleSuccess: (data) => {
      setBrand({
        name: '',
        logo: data?.appearance.logo || '',
        cardColor: data?.appearance.cardColor || '',
        brandName: data?.appearance.brandName || '',
        termsLink: data?.appearance.termsLink || '',
        codeAdapter: data?.appearance.codeAdapter || DEFAULT_CODE_ADAPTER,
        verifyMethods: data?.appearance.verifyMethods || DEFAULT_VERIFY_METHODS,
        phoneNumberVerified: data?.phoneNumberVerified || false,
        dadataApiKey: data?.dadataApiKey || '',
        phoneMask: data?.appearance.phoneMask || DEFAULT_MASK,
      })
      setCurrentStep(data?.step)

      setUserPhoneNumber(data?.phoneNumber)

      if (data?.client) {
        setCommonClientData(data?.client)

        setWalletsLink(data.client.walletsLink)
      }

      if (data?.fields.length) setFields(data?.fields)
    },

    handleFinally: () => {
      setIsInitRequestLoading(false)
    },
  })

  const search = window.location.search
  const params = new URLSearchParams(search)
  const queryParams = Object.fromEntries(params)

  useEffect(() => {
    setIsInitRequestLoading(true)
    handleFetch({
      formCode: query,
      query: queryParams,
    })
  }, [])

  return <Outlet />
}
