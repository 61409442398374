import { CommunicationTypes } from '../CommunicationTypes/CommunicationTypes'
import { AuthForm } from '../AuthForm/AuthForm'
import { ClientForm } from '../ClientForm/ClientForm'
import { useStepsContext } from '~/src/stepsContext'

export const FormRouter: React.FC = () => {
  const { currentStep } = useStepsContext()

  if (currentStep === 'start') return <CommunicationTypes />
  if (currentStep === 'form') return <ClientForm />
  if (currentStep === 'otp') return <AuthForm />
}
