import React, { useEffect } from 'react'
import styles from './ClientForm.module.scss'
import { Form } from 'react-aria-components'
import { FieldComponent } from './FieldComponent'
import { Button } from '../ui/Button/Button'
import { isMobileDevice } from '~/src/utils/isMobileDevice'
import { useApi } from '~/src/api'
import { Error, SubmitClientResponse } from '~/src/types'
import { useStepsContext } from '~/src/stepsContext'
import { errorMap } from '~/src/utils/error'
import { InputMask } from '../ui/InputMask/InputMask'

const scrollToInvalidField = () => {
  const invalidInputs = document.querySelectorAll('input[aria-invalid="true"]')

  if (invalidInputs.length) {
    invalidInputs[0]?.scrollIntoView?.({
      block: 'center',
    })
  }

  return !!invalidInputs.length
}

export const ClientForm: React.FC = () => {
  const {
    brand,
    commonClientData,
    fields,
    userPhoneNumber,
    setUserPhoneNumber,
    setWalletsLink,
    setCurrentStep,
    setIsChangePhoneNumber,
    setCommonClientData,
  } = useStepsContext()

  const defaultValues = commonClientData?.fields
  const defaultChildren = commonClientData?.children
  const dadataApiKey = brand?.dadataApiKey

  const [validationErrors, setValidationErrors] = React.useState<
    Error['validationErrors']
  >([])

  const validationErrorsMap = validationErrors.reduce(
    (acc, item) => {
      acc[item.code] = item.errorCode

      return acc
    },
    {} as Record<string, number>,
  )

  const { handleFetch, isLoading, error } = useApi<SubmitClientResponse>({
    domain: 'submit-client',
    handleSuccess: ({ client }) => {
      if (client) {
        setCommonClientData(client)
        setWalletsLink(client.walletsLink)
      }

      if (!client.walletsLink) {
        setCurrentStep('thankyou')

        return
      }

      if (isMobileDevice()) {
        setCurrentStep('thankyou')

        setTimeout(() => {
          document.location.href = client.walletsLink as string
        }, 1000)
      } else {
        setCurrentStep('qr')
      }
    },

    handleError: (error) => {
      if (error?.validationErrors) {
        setValidationErrors(error?.validationErrors)
        setTimeout(() => {
          scrollToInvalidField()
        }, 100)
      }
    },
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const hasScroll = scrollToInvalidField()

    if (hasScroll) return

    const formData = Object.fromEntries(
      new FormData(event.currentTarget),
    ) as Record<string, [] | string | boolean>

    const children = []

    for (const key in formData) {
      if (formData[key] === 'true') {
        formData[key] = true
      }

      if (formData[key] === 'false') {
        formData[key] = true
      }

      if (key.startsWith('children-name')) {
        const index = key.replace('children-name', '')
        const birthdateKey = `children-birthdate${index}`
        if (formData[birthdateKey]) {
          children.push({
            name: formData[key],
            birthdate: formData[birthdateKey],
          })
        }

        delete formData[`children-name${index}`]
        delete formData[birthdateKey]
      }

      if (formData[key] === '') delete formData[key]
    }

    const payload = {
      ...defaultValues,
      ...formData,
      children: defaultChildren ? [...defaultChildren, ...children] : children,
    }

    handleFetch({ form: payload })
  }

  const handleClickChange = () => {
    setIsChangePhoneNumber(true)
    setCurrentStep('otp')
    setUserPhoneNumber('')
  }

  useEffect(() => {
    const inputs = document.querySelectorAll<HTMLInputElement>(
      'input:not([disabled])',
    )

    if (inputs[0]) inputs[0].focus()
  }, [])

  return (
    <Form
      id='clientForm'
      validationBehavior='aria'
      className={styles.form}
      onSubmit={handleSubmit}
    >
      <div className={styles.form__fields}>
        <div className={styles.form__field}>
          <InputMask
            isDisabled
            label='Мобильный телефон'
            inputMode='numeric'
            type='tel'
            mask=''
            value={userPhoneNumber}
            withChange
            handleClickChange={handleClickChange}
          />
        </div>

        {fields?.map((field) => (
          <div className={styles.form__field} key={field.code}>
            <FieldComponent
              dadataApiKey={dadataApiKey}
              defaultChildren={defaultChildren}
              field={field}
              defaultValues={defaultValues}
              errorMessage={errorMap[validationErrorsMap[field.code]]}
            />
          </div>
        ))}
      </div>

      <Button isLoading={isLoading} type='submit' title='ПОЛУЧИТЬ КАРТУ' />

      {error && <div className={styles.form__error}>{error}</div>}
    </Form>
  )
}
