import styles from './DotLoader.module.scss'

export const DotsLoader = () => {
  return (
    <div className={styles['bouncing-loader']}>
      <div />
      <div />
      <div />
    </div>
  )
}
