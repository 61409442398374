import './App.css'
import { Route, Routes } from 'react-router-dom'
import './App.css'

import { OutletComponent } from './components/OutletComponent'
import { MainPage } from './pages/MainPage/MainPage'

function App() {
  return (
    <Routes>
      <Route path='/' element={<OutletComponent />}>
        <Route path=':query' element={<MainPage />} />
      </Route>
    </Routes>
  )
}

export default App
