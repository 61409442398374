const ORIGIN = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest'
const MOKK_API_KEY = '4975418d48482494fd143286b6302ef4a5a5bacd'

const makeRequest = (
  url: string,
  body: Record<string, unknown>,
  apiKey: string,
) =>
  fetch(`${ORIGIN}/${url}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Token ${apiKey}`,
    },
    body: JSON.stringify(body),
  }).then((response) => response.json())

export const fetchSuggestions = (
  apiKey: string = MOKK_API_KEY,
  type: string,
  query: string,
) => {
  switch (type) {
    case 'fullName': {
      return makeRequest('fio', { query }, apiKey)
    }

    case 'name': {
      return makeRequest('fio', { query, parts: ['NAME'] }, apiKey)
    }

    case 'surname': {
      return makeRequest('fio', { query, parts: ['SURNAME'] }, apiKey)
    }

    case 'patronymic': {
      return makeRequest('fio', { query, parts: ['PATRONYMIC'] }, apiKey)
    }

    case 'email': {
      return makeRequest('email', { query }, apiKey)
    }
  }

  return Promise.resolve({ suggestions: [] })
}
