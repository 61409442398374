export const IconApply = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='13'
    height='9'
    viewBox='0 0 13 9'
  >
    <path
      fill='#56699F'
      fillRule='nonzero'
      d='M11.173 1.799L4.696 8.275a.444.444 0 0 1-.627 0L.129 4.31a.445.445 0 0 1 0-.629l.942-.94a.444.444 0 0 1 .627 0l2.688 2.705L9.603.229a.445.445 0 0 1 .629 0l.94.942a.442.442 0 0 1 .001.628z'
    />
  </svg>
)
