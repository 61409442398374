export const IconSMS = () => (
  <svg
    width='28'
    height='28'
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.5 4C8.95425 4 4 7.64973 4 12.6424C4 14.4129 4.64662 16.1514 5.79112 17.5004C5.83925 19.0808 4.896 21.3487 4.04725 23C6.324 22.5941 9.56062 21.6976 11.028 20.8098C19.1095 22.7504 25 17.8691 25 12.6424C25 7.62295 20.0116 4 14.5 4Z'
      fill='white'
    />
  </svg>
)
