import styles from './AuthForm.module.scss'
import classNames from 'classnames'
import { Button } from '../ui/Button/Button'
import React, { useCallback, useState } from 'react'
import { SendCode } from './SendCode/SendCode'
import { TryByMessengers } from './TryByMessengers'
import { useApi } from '../../api'
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha'

import { Client } from '~/src/types'
import { useStepsContext } from '~/src/stepsContext'
import { InputMask } from '../ui/InputMask/InputMask'

export const AuthForm: React.FC = () => {
  const {
    brand,
    userPhoneNumber,
    agreedWithTerms,
    verifyByTelegram,
    verifyByWhatsapp,
    isChangePhoneNumber,
    setUserPhoneNumber,
    setIsChangePhoneNumber,
  } = useStepsContext()

  const [phoneNumber, setPhoneNumber] = useState(userPhoneNumber || '')
  const [resetCaptcha, setResetCaptcha] = useState(0)
  const [sendCodeView, setSendCodeView] = useState(
    !!userPhoneNumber && !isChangePhoneNumber,
  )

  const handleReset = () => setResetCaptcha((prev) => prev + 1)

  const { error, handleFetch, isLoading } = useApi<{ client: Client }>({
    only500: true,
    domain: 'send-code',
    handleSuccess: () => setSendCodeView(true),
  })

  const [visible, setVisible] = useState(false)

  const handleSendCode = () => {
    setUserPhoneNumber(phoneNumber)
    setIsChangePhoneNumber(false)
    handleReset()
    setVisible(true)
  }

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSendCode()
    }
  }

  const handleClickChange = () => {
    setPhoneNumber('')
    setIsChangePhoneNumber(true)
    setSendCodeView(false)
  }

  const phoneMask =
    sendCodeView || !brand ? '' : brand?.phoneMask.replace(/[#]/g, '9')

  const placeholder = brand?.phoneMask.replace(/[#]/g, '0')

  const handleChallengeHidden = useCallback(() => setVisible(false), [])

  return (
    <>
      <div className={styles.form}>
        <div className={styles.form__wrapper}>
          <div>
            <div className={styles.form__field}>
              <InputMask
                autoFocus
                isDisabled={sendCodeView}
                isRequired={!sendCodeView}
                label='Мобильный телефон'
                inputMode='numeric'
                type='tel'
                placeholder={placeholder}
                mask={phoneMask}
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event)}
                withChange={sendCodeView}
                handleClickChange={handleClickChange}
                onKeyUp={handleKeyUp}
              />
            </div>

            <div className={styles.form__phone_submit}>
              {sendCodeView ? (
                <>
                  <SendCode refetchCode={handleSendCode} />
                </>
              ) : (
                <Button
                  isLoading={isLoading}
                  title='ПОЛУЧИТЬ КОД'
                  disabled={phoneMask.length !== phoneNumber.length}
                  variant='sms'
                  onClick={() => handleSendCode()}
                />
              )}
            </div>
          </div>

          {error && <div className={styles.form__error}>{error}</div>}

          <div className={styles.social}>
            <div
              className={classNames(styles.socialTitle, {
                [styles.socialTitleCodeView]: sendCodeView,
              })}
            >
              {sendCodeView ? (
                <>
                  <span className={styles.socialHint}>Не приходит код?</span>
                  <span> Попробуйте через</span>
                </>
              ) : (
                <span>Через</span>
              )}
            </div>

            <TryByMessengers
              verifyByTelegram={verifyByTelegram}
              verifyByWhatsapp={verifyByWhatsapp}
            />
          </div>
        </div>
      </div>

      <InvisibleSmartCaptcha
        key={resetCaptcha}
        hideShield
        sitekey={import.meta.env.VITE_CAPTCHA_KEY}
        onSuccess={(token: string) => {
          handleFetch(
            {
              agreedWithTerms,
              phoneNumber,
            },
            token,
          )
        }}
        onChallengeHidden={handleChallengeHidden}
        visible={visible}
      />
    </>
  )
}
