import styles from './InnerPage.module.scss'
import classNames from 'classnames'

type InnerPageProps = {
  title?: string
  containerClassName?: string
  subTitle?: string
  mobileSubTitle?: string
  center?: boolean
  children: React.ReactNode
}

export const InnerPage: React.FC<InnerPageProps> = ({
  center = false,
  mobileSubTitle,
  subTitle,
  title,
  containerClassName,
  children,
}) => {
  return (
    <div
      className={classNames(styles.inner, containerClassName, {
        [styles.center]: center,
      })}
    >
      <h1
        className={classNames(styles.inner__title, {
          [styles.center]: center,
        })}
      >
        {title}
      </h1>

      {subTitle && (
        <div
          className={classNames(styles.inner__subtitle, {
            [styles.center]: center,
          })}
        >
          {subTitle}
        </div>
      )}

      {mobileSubTitle && (
        <div
          className={classNames(styles['inner__mobile-subtitle'], {
            [styles.center]: center,
          })}
        >
          {mobileSubTitle}
        </div>
      )}

      <div className={styles.inner__content}>{children}</div>
    </div>
  )
}
