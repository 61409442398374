export const IconPhone = () => (
  <svg
    width='29'
    height='28'
    viewBox='0 0 29 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clip-rule='evenodd'
      d='M9.44413 5.71191C8.27539 5.39875 7.07407 6.09233 6.7609 7.26107L3.0752 21.0163C2.76203 22.1851 3.45561 23.3864 4.62436 23.6996L12.031 25.6842C13.1998 25.9973 14.4011 25.3037 14.7143 24.135L18.4 10.3798C18.7131 9.21101 18.0195 8.00969 16.8508 7.69653L9.44413 5.71191ZM16.0249 10.7783C16.1679 10.2449 15.8513 9.69654 15.3178 9.5536L9.88794 8.09867C9.35448 7.95573 8.80614 8.27231 8.6632 8.80578L5.72001 19.7899C5.57707 20.3234 5.89365 20.8717 6.42712 21.0146L11.857 22.4696C12.3905 22.6125 12.9388 22.2959 13.0817 21.7625L16.0249 10.7783Z'
      fill='white'
    />
    <path
      d='M24.5502 11.778C25.1247 12.0119 25.7802 11.7359 26.0142 11.1614C26.2195 10.6572 26.4081 9.8561 26.413 8.84498C26.4179 7.82718 26.2178 6.79417 25.7632 5.78212C25.1004 4.3071 24.0628 3.23519 22.8683 2.50539C22.4505 2.25009 22.102 2.08948 21.8693 2.00714C21.2845 1.80018 20.6427 2.10649 20.4357 2.69135C20.2461 3.22736 20.4876 3.81135 20.9797 4.06433L21.1199 4.12488C21.2131 4.1579 21.4216 4.25387 21.697 4.42222C22.5308 4.9318 23.2539 5.67861 23.7141 6.70277C24.0313 7.40886 24.17 8.12524 24.1666 8.83417C24.1633 9.52714 24.0387 10.0562 23.9336 10.3141C23.6996 10.8886 23.9757 11.544 24.5502 11.778Z'
      fill='white'
    />
    <path
      d='M21.2417 10.5108C21.8449 10.6557 22.4513 10.2842 22.5962 9.68098C22.6549 9.43677 22.7005 9.0834 22.6853 8.65886C22.6688 8.19138 22.5763 7.73306 22.3819 7.30057C22.1046 6.68326 21.6644 6.20025 21.1522 5.82271L20.9239 5.66549C20.8553 5.62161 20.7946 5.58633 20.744 5.55928C20.1967 5.26724 19.5163 5.47412 19.2242 6.02143C18.9565 6.52308 19.108 7.13667 19.5567 7.46038L19.7552 7.58533C19.7736 7.59802 19.7952 7.61331 19.8193 7.63099C20.0468 7.7986 20.2365 8.00696 20.3328 8.22096C20.3975 8.36534 20.4334 8.5428 20.4404 8.7385C20.4456 8.88589 20.4353 9.01246 20.4236 9.09262L20.4119 9.15629C20.267 9.75948 20.6386 10.3659 21.2417 10.5108Z'
      fill='white'
    />
  </svg>
)
