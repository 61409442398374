export const IconTelegram = () => (
  <svg
    width='29'
    height='28'
    viewBox='0 0 29 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.2872 12.8353L17.7979 7.49516C19.0324 6.93889 23.2192 5.15883 23.2192 5.15883C23.2192 5.15883 25.1515 4.38006 24.9905 6.27137C24.9368 7.05014 24.5074 9.77586 24.078 12.7284L22.7361 21.466C22.7361 21.466 22.6287 22.7454 21.7162 22.968C20.8037 23.1905 19.3008 22.1892 19.0324 21.9667C18.8177 21.7998 15.0067 19.2966 13.6111 18.0728C13.2354 17.739 12.806 17.0715 13.6648 16.2927C15.5971 14.4571 17.9052 12.1764 19.3008 10.7301C19.9449 10.0625 20.589 8.505 17.9052 10.3963L10.3328 15.6723C10.3328 15.6723 9.47394 16.2286 7.86366 15.7279C6.25337 15.2273 4.37471 14.5598 4.37471 14.5598C4.37471 14.5598 3.08648 13.7254 5.2872 12.8353Z'
      fill='white'
    />
  </svg>
)
