import { useState } from 'react'

import styles from './ChildrenInput.module.scss'
import { Autocomplete } from '../../ui/Autocomplete/Autocomplete'
import { fetchSuggestions } from '../../../dadata'
import { InputMask } from '../../ui/InputMask/InputMask'
import { validateDate } from '../../../utils/validateDate'

type ChildrenInputProps = {
  dadataApiKey?: string
  defaultValue?: Array<{ name: string; birthdate: string }>
}

export const ChildrenInput: React.FC<ChildrenInputProps> = ({
  defaultValue,
  dadataApiKey,
}) => {
  const initialDates = defaultValue?.length
    ? [...defaultValue.map((child) => child.birthdate), '']
    : ['']

  const initialNames = defaultValue?.length
    ? [...defaultValue.map((child) => child.name), '']
    : ['']

  const [names, setNames] = useState(initialNames)
  const [dates, setDates] = useState(initialDates)

  const handleInputNameChange = (index: number, value: string) => {
    const newInputs = [...names]
    newInputs[index] = value

    if (value === '') {
      const emptyIndex = newInputs.findIndex(
        (input, i) => input === '' && i !== index,
      )
      if (emptyIndex !== -1) {
        newInputs.splice(emptyIndex, 1)
      }
    } else if (
      newInputs.filter((input) => input !== '').length === newInputs.length
    ) {
      newInputs.push('')
    }

    setNames(newInputs)
  }

  return (
    <div className={styles.container}>
      {names.map((_, index) => (
        <div key={index + 'name'} className={styles.row}>
          <Autocomplete
            isDisabled={defaultValue ? defaultValue?.length > index : false}
            defaultInputValue={names[index] || ''}
            name={`children-name${index}`}
            label={index === 0 ? 'Дети' : undefined}
            isRequired={!!dates[index]}
            fetchOptions={(query) =>
              fetchSuggestions(dadataApiKey, 'name', query)
            }
            onInputChange={(data) => {
              handleInputNameChange(index, data)
            }}
          />

          <InputMask
            isDisabled={defaultValue ? defaultValue?.length > index : false}
            name={`children-birthdate${index}`}
            value={dates[index]}
            isRequired={index !== names.length - 1}
            mask='99.99.9999'
            placeholder='01.01.1900'
            onChange={(data) => {
              const newDates = [...dates]
              newDates[index] = data
              setDates(newDates)
            }}
            inputMode={'numeric'}
            onValid={(value) => (validateDate(value) ? '' : 'Неверная дата')}
          />
        </div>
      ))}
    </div>
  )
}
