import { IconPhone } from '../../../icons/IconPhone'
import { IconSMS } from '../../../icons/IconSMS'
import { IconTelegram } from '../../../icons/IconTelegram'
import { IconWhatsapp } from '../../../icons/IconWhatsapp'
import { DotsLoader } from '../DotLoader/DotLoader'
import styles from './Button.module.scss'
import classNames from 'classnames'

type ButtonProps = {
  title: string
  href?: string
  className?: string
  type?: 'submit' | 'button'
  variant?: 'telegram' | 'whatsapp' | 'phone' | 'sms' | 'simple'
  disabled?: boolean
  isLoading?: boolean
  onClick?: () => void
}

const ICONS = {
  telegram: IconTelegram,
  whatsapp: IconWhatsapp,
  phone: IconPhone,
  sms: IconSMS,
  simple: null,
}

export const Button: React.FC<ButtonProps> = ({
  disabled = false,
  isLoading = false,
  href,
  title,
  className,
  variant = 'simple',
  onClick,
  type = 'button',
}) => {
  const ButtonComponent = href ? 'a' : 'button'

  const Icon = ICONS[variant]

  return (
    <ButtonComponent
      type={type}
      className={classNames(styles.button, styles[variant], className, {
        [styles.button_disabled]: disabled,
      })}
      href={href}
      onClick={onClick}
    >
      <div className={styles.content}>
        {isLoading ? (
          <DotsLoader />
        ) : (
          <>
            {title}
            {Icon && <Icon />}
          </>
        )}
      </div>
    </ButtonComponent>
  )
}
